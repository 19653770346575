import { Breadcrumbs } from '@components/shared';
import type { IPage, IPost, IProduct, ILocation } from '@standardTypes';
import React from 'react';
import { Layout } from '@components/layout';
import { withHtml } from "@components/logic"

interface ResearchProps {
    pageContext: {
        page: IPage;
        pages: Array<IPage>;
        translationUrls: Object;
    };
    location: ILocation;
}

const Content = withHtml("article")

const Research: React.FC<ResearchProps> = ({ pageContext, location }) => {
    const { page, translationUrls } = pageContext;
    console.log('page', pageContext)

    return (
        <Layout {...page} translationUrls={translationUrls}>
            <h1>{page.title}</h1>
            <Content>{page.content}</Content>
        </Layout>
    );
};

export default Research;